import spbw from "../../utils/spbw";

import origLogo from '../../assets/img/original.svg';
import cls from './footer.module.css';

function Footer() {
    return (
        <footer className={cls.footer}>
            <div className={cls.part_top}>
                <div className={spbw('container', cls.container)}>
                    <div className={cls.section}>
                        <h3 className={cls.section_header}>
                            Disclaimer
                        </h3>
                        <p className={cls.section_text}>
                            This is made for educational purpose, its not my intention to steal players from the original game :D
                        </p>
                        <div className={cls.section_image}>
                            <a href="https://geoguessr.com/" target="_blank" rel="noreferrer">
                                <img src={origLogo} alt="GeoGuessr" />
                            </a>
                        </div>
                    </div>
                    <div className={cls.section}>
                        <h3 className={cls.section_header}>
                            Contact
                        </h3>
                        <div className={cls.section_text}>
                            <ul className={cls.list}>
                                <li>
                                    E-mail:{' '}
                                    <a
                                        href="mailto:daniel@baekkegaard.dk"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        daniel@baekkegaard.dk
                                    </a>
                                </li>
                                <li>
                                    Discord:{' '}
                                    <a href="https://discordapp.com/users/1057409135223771156" target="_blank" rel="noreferrer">baekke.developer</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cls.part_bottom}>
                <div className={spbw('container', cls.container)}>
                    <p>
                        &copy; 2024 Baekke
                    </p>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
