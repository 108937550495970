import Header from '../../components/Header/Header';
import EnterGame from '../../components/EnterGame/EnterGame';
import Footer from '../../components/Footer/Footer';

import cls from './home.module.css';

function Home() {
    return (
        <div>
            <Header label="Stats" href="/stats" />
            <main className="header-above">
                <div className="container main-container">
                    <section className="section">
                        <h2 className="title section-title title-center">Description</h2>
                        <p className="paragraph section-paragraph paragraph-center">
Its just a geoguesser clone
                        </p>
                    </section>
                    <section className="section">
                        <h2 className="title section-title title-center">Enter a game</h2>
                        <EnterGame className={cls.enter_game} />
                    </section>
        
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default Home;
